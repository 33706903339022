import { ChainId } from '@uniswap/sdk'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'

import styled from 'styled-components'
import { ReactComponent as SvgLogo } from '../../assets/svg/qday_swap_logo.svg';

import { useActiveWeb3React } from '../../hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'

import Web3Status from '../Web3Status'

const HeaderFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: "Manrope";
  height: 4.5rem;
  box-sizing: content-box;
  flex-shrink: 0;
  border-left: 1px solid ${({ theme }) => theme.border2};
  background: ${({ theme }) => theme.bg1};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg1)};
  border: 1px solid ${({ theme }) => theme.border4};
  white-space: nowrap;
  width: 100%;
  border-radius: 9999px;
  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
  
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 6px;
  padding: 12px 20px;
  color: ${({ theme }) => theme.textOrange};
  background-color: ${({ theme }) => theme.bg8};
  font-size: 16px;
  font-weight: 400;
`

const BalanceText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  padding: 12px;
  color: ${({ theme }) => theme.value2};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.QDAY]: 'QDay Network'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()

  return (
    <HeaderFrame>
      <HeaderElement>
        <SvgLogo/>
      </HeaderElement>
      <HeaderElement>
        <TestnetWrapper>
          {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
        </TestnetWrapper>

        <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
          {account && userEthBalance ? (
            <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
              {userEthBalance?.toSignificant(4)} QDAY
            </BalanceText>
          ) : null}
          <Web3Status />
        </AccountElement>
      </HeaderElement>
    </HeaderFrame>
  )
}
