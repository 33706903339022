import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ThemeContext } from 'styled-components'

// Import SVG components
import { ReactComponent as Logo } from '../../assets/svg/qday_logo.svg'
import { ReactComponent as LogoText } from '../../assets/svg/qday_finance.svg'
import { ReactComponent as DashboardIcon } from '../../assets/svg/dashboard.svg'
import { ReactComponent as QDayBridgeIcon } from '../../assets/svg/qday_bridge.svg'
import { ReactComponent as QDaySwapIcon } from '../../assets/svg/qday_swap.svg'
import { ReactComponent as QDayStakingIcon } from '../../assets/svg/qday_staking.svg'
import { ReactComponent as QDayLendIcon } from '../../assets/svg/qday_lend.svg'
import { ReactComponent as AbelStakingIcon } from '../../assets/svg/abel_staking.svg'
import { TYPE } from '../../theme'

interface LinkItemProps {
  isActive?: boolean;
}

const SidebarWrapper = styled.div`
    width: 256px;
    height: 100vh;
    background: ${({ theme }) => theme.bg1};
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
`

const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
    gap: 4px;
    padding: 0 1rem;
    //border-bottom: 1px solid ${({ theme }) => theme.border1};

    svg {
        height: 32px;
        width: auto;
    }
`

const NavWrapper = styled.nav`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
`

const LinkItem = styled.div<LinkItemProps>`
    width: 224px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    height: 3.5rem;
    padding: 0 1rem 0 3.5rem;
    position: relative;
    transition: all 0.2s ease;
    border-radius: 8px;
    margin-bottom: 16px;

    svg {
        flex-shrink: 0;
        transition: fill 0.2s ease;
    }

    &:hover {
        background-color: ${({ theme }) => theme.bg2};

        svg {
            fill: ${({ theme }) => theme.primary1};
        }

        a {
            color: ${({ theme }) => theme.primary1};
        }
    }

    ${({ isActive, theme }) =>
            isActive &&
            `
        background-color: ${theme.primary4};
        
        svg {
            fill: ${theme.primary1};
        }
        
        a {
            color: ${theme.primary1};
        }
    `}

    a {
        text-decoration: none;
        width: 100%;
        line-height: 16px;
        letter-spacing: 0;
        font-family: Manrope, sans-serif;
    }
`

interface NavItemProps {
  to: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  children: React.ReactNode;
  isExternal?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ to, Icon, children, isExternal }) => {
  const location = useLocation()
  const theme = useContext(ThemeContext)
  const isActive = location.pathname === to || (to==="/swap" && location.pathname==="/pool")

  const LinkComponent = ({ children }: { children: React.ReactNode }) => {
    if (isExternal) {
      return (
        <a href={to} target="_self" rel="noopener noreferrer">
          <TYPE.black
            fontWeight={500}
            fontSize={16}
            color={isActive ? theme.primary1 : theme.text2}
          >
            {children}
          </TYPE.black>
        </a>
      )
    }

    return (
      <Link to={to}>
        <TYPE.black
          fontWeight={500}
          fontSize={16}
          color={isActive ? theme.primary1 : theme.text2}
        >
          {children}
        </TYPE.black>
      </Link>
    )
  }

  return (
    <LinkItem isActive={isActive}>
      <Icon
        fill={isActive ? theme.primary1 : theme.text2}
        style={{ transition: 'fill 0.2s ease' }}
      />
      <LinkComponent>{children}</LinkComponent>
    </LinkItem>
  )
}

const NAV_ITEMS = [
  { path: '/', Icon: DashboardIcon, text: 'Dashboard', isExternal: false },
  { path: 'https://devnet-finance.qday.ninja', Icon: QDayBridgeIcon, text: 'QDayBridge', isExternal: true },
  { path: '/swap', Icon: QDaySwapIcon, text: 'QDaySwap', isExternal: false },
  { path: '/staking', Icon: QDayStakingIcon, text: 'QDayStaking', isExternal: false },
  { path: '/lend', Icon: QDayLendIcon, text: 'QDayLend', isExternal: false },
  { path: '/abel-staking', Icon: AbelStakingIcon, text: 'AbelStaking', isExternal: false },
] as const

const Sidebar: React.FC = () => {
  const theme = useContext(ThemeContext)

  return (
    <SidebarWrapper>
      <LogoWrapper>
        <Logo fill={theme.text1} />
        <LogoText fill={theme.text1} />
      </LogoWrapper>

      <NavWrapper>
        {NAV_ITEMS.map((item) => (
          <NavItem
            key={item.path}
            to={item.path}
            Icon={item.Icon}
            isExternal={item.isExternal}
          >
            {item.text}
          </NavItem>
        ))}
      </NavWrapper>
    </SidebarWrapper>
  )
}

export default Sidebar
