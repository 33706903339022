import React from 'react'
import styled from 'styled-components'
import './style.css'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 606px;
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.border3};
  border-radius: 24px;
  padding:  24px 32px;
  font-family: "Manrope";
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
